import { screenApi } from 'apis'
import { ActionsCreator, basicHandlers } from '../lib'
import { EventSchema, OverrideSchema } from 'redux/schemas'
import { validateAndFormatUserSubmittedDate } from 'helpers/date'

export const eventsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'events' })

export const fetchEvents = (query, config) =>
  eventsActions.fetch({
    url: '/events',
    query,
    schema: { events: [EventSchema] },
    ...config,
  })

export const fetchEvent = (id, date) => {
  date = date === 'next' ? date : validateAndFormatUserSubmittedDate(date)
  const query = date ? { date } : {}
  return eventsActions.fetch({
    url: `/events/${id}`,
    query,
    schema: { event: EventSchema },
  })
}

export const createEvent = (payload) =>
  eventsActions.create({
    url: '/events',
    payload,
    schema: { event: EventSchema },
  })

export const updateEvent = (id, payload) =>
  eventsActions.update({
    url: `/events/${id}`,
    payload,
    schema: { event: EventSchema },
  })

export const deleteEvent = (id, query, config) =>
  eventsActions.delete({
    url: `/events/${id}`,
    query,
    schema: { event: EventSchema },
    ...config,
  })

export const updateScheduleEventByDate = (id, date, payload) =>
  eventsActions.update({
    url: `/events/${id}/planning/${date.format('YYYY-MM-DD')}`,
    payload: {
      ...payload,
      open: true,
    },
    schema: { overrides: OverrideSchema },
  })

export const shareEvent = (id, payload) =>
  eventsActions.create({
    url: `/events/${id}/share`,
    payload,
    schema: { event: EventSchema },
  })

export const eventsCustomHandlers = {
  [eventsActions.actionTypes.DELETED]: basicHandlers.mergeEntities,
}
