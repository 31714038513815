/**
 * This helper is meant to help us move from legacy data format to newer immutable data format
 * here should be added functions that will primarily check for correct immutable data
 * and fallback to different legacy data format if the prefered one is absent.
 * All access to legecay data format should be logged at info level in dev mod.
 */

import { Record, Map } from 'immutable'
import { isObject } from 'lodash'

const log = (...args) => {
  if (process.env.NODE_ENV === 'production') return
  const e = new Error()
  console.debug('LEGACY:', ...args, e.stack) // eslint-disable-line no-console
}

export function getCoordinatesFromPlace(place) {
  const coordinates = { lat: undefined, lng: undefined }
  if (Record.isRecord(place)) {
    coordinates.lat = place.coordinates.get('latitude')
    coordinates.lng = place.coordinates.get('longitude')
  } else if (Map.isMap(place)) {
    log('using Map for "place" instead of Record')
    coordinates.lat = place.getIn(['coordinates', 'latitude'])
    coordinates.lng = place.getIn(['coordinates', 'longitude'])
  } else if (isObject(place) && isObject(place.coordinates)) {
    log('using POJO for "place" instead of Record')
    coordinates.lat = place.coordinates.latitude
    coordinates.lng = place.coordinates.longitude
  }
  return coordinates
}

export function getEventTitle(language = 'en', event) {
  if (!event || !event.title) return ''
  if (typeof event.title === 'string') return event.title
  let title = event.title
  if (!Map.isMap(title) && isObject(title)) title = new Map(title)
  return Map.isMap(title) ? title.get(language, title.get('default', '')) : ''
}

export function ensureJSContent(content) {
  return content.toJS ? content.toJS() : content
}
