import moment from 'moment'
import { emptyBooking, emptyRequest, emptyEvent, ReservationRecord } from 'redux/schemas'
import { isEmpty } from 'lodash'

export function filterBookings({ bookings, status, groupStatus, event }) {
  return bookings.filter((booking) => {
    if (status && booking.status !== status) return false
    if (groupStatus && booking.groupStatus !== groupStatus) return false
    if (event && (booking.event_id || booking.event) !== event.id) return false
    return true
  })
}

/**
 * @param {Immutable.Record} booking
 * @param {Immutable.Record} request
 * @returns {object}
 */
export function whatCanIDoNow(booking = emptyBooking, request = emptyRequest, event = emptyEvent) {
  const reservation = booking.id
    ? new ReservationRecord(booking).merge({ booking_id: booking.id })
    : new ReservationRecord(request).merge({ request_id: request.id })

  // both booking & request
  const isPrivate = !!event.privatized_by || (request.id && !!request.privatized_at)

  const isPast = reservation.id && !!reservation.date && moment.utc(reservation.date).isBefore(moment.utc(), 'day')

  // requests
  const requestHasBeenCancelled = request.id && !!request.canceled_at
  const requestHasExpired = request.id && !!request.expired_at

  const requestCanBeCanceled = request.id && !requestHasExpired && !requestHasBeenCancelled && !isPast

  const requestInformationCanBeUpgraded = request.id && !request.date && requestCanBeCanceled
  const requestCanBeApproved =
    request.id &&
    request.date &&
    !request.approved_at &&
    requestCanBeCanceled &&
    (request.privatized_at ? !event.privatized_by : true)

  const requestHasBeenApproved = request.id && !!request.approved_at && requestCanBeCanceled

  const privateRequestCanBeChanged = request.id && !!request.privatized_at && !request.approved_at
  const requestCanBePrivatized = request.id && request.date && !request.approved_at && requestCanBeCanceled

  // bookings
  const isPrivateBookingPending = booking.id && booking.should_privatize_date && booking.status === 'tentative'
  const bookingCanBeValidated = !isPast && booking.id && booking.status === 'tentative'
  const bookingCanOccur = booking.id && ['tentative', 'validated_by_host'].includes(booking.status)
  const bookingHasHappened = booking.id && ['completed', 'host_payed'].includes(booking.status)
  const bookingJustHappened = booking.id && ['completed', 'validated_by_host'].includes(booking.status)

  const cancellationDeadline = moment.duration(event.cancellation_deadline)
  const bookingIsWithinCancellationDeadline =
    !!booking.begins_at &&
    booking.status !== 'tentative' &&
    moment.utc(booking.begins_at).subtract(cancellationDeadline).isSameOrBefore(moment.utc())

  const bookingHasBeenCanceledByHost = booking.id && ['canceled_by_host', 'rejected_by_host'].includes(booking.status)
  const bookingHasBeenCanceledByGuest =
    booking.id && ['canceled_by_guest', 'canceled_under_delay', 'canceled_by_partner'].includes(booking.status)
  const bookingHasBeenCanceledByUs = booking.id && booking.status === 'canceled_by_us'
  const bookingHasBeenCanceled =
    booking.id && (bookingHasBeenCanceledByHost || bookingHasBeenCanceledByGuest || bookingHasBeenCanceledByUs)

  const bookingHasExpired = booking.id && booking.status === 'expired'
  const bookingHasBeenRefunded = booking.id && booking.status === 'refunded'
  const bookingPaymentRefused = booking.id && booking.status === 'payment_refused'

  const bookingHasBeenReviewedByHost = booking.id && booking.host_already_left_review
  const bookingHasBeenReviewedByGuest = booking.id && booking.guest_already_left_review

  const bookingCanBeCanceled =
    !isPast &&
    booking.id &&
    !bookingHasHappened &&
    !bookingHasBeenCanceled &&
    !bookingHasExpired &&
    !bookingHasBeenRefunded &&
    !bookingPaymentRefused

  const hostHasBeenPaid = booking.status === 'host_payed'
  const bookingIssueCanBeReported = (isPast && bookingJustHappened) || hostHasBeenPaid

  // results
  const canChat = (!bookingHasBeenCanceled && !bookingHasHappened) || (!requestHasExpired && !requestHasBeenCancelled)
  const canBeCanceled = requestCanBeCanceled || bookingCanBeCanceled

  const canBeReviewedByGuest = bookingHasHappened && !bookingHasBeenReviewedByGuest
  const canBeReviewedByHost = bookingHasHappened && !bookingHasBeenReviewedByHost

  const canShareLink =
    reservation.date &&
    !isPast &&
    !bookingHasExpired &&
    !requestHasExpired &&
    !isPrivateBookingPending &&
    (requestInformationCanBeUpgraded ||
      requestCanBeCanceled ||
      requestCanBeApproved ||
      requestHasBeenApproved ||
      requestCanBePrivatized ||
      privateRequestCanBeChanged ||
      bookingCanOccur ||
      bookingCanBeValidated)

  return {
    isPast,
    isPrivate,

    privateRequestCanBeChanged,
    requestCanBePrivatized,
    requestInformationCanBeUpgraded,
    requestCanBeApproved,
    requestHasBeenApproved,
    requestCanBeCanceled,

    bookingCanBeValidated,
    bookingCanOccur,
    bookingCanBeCanceled,
    bookingIsWithinCancellationDeadline,
    bookingIssueCanBeReported,

    canBeCanceled,
    canChat,
    canBeReviewedByGuest,
    canBeReviewedByHost,

    canShareLink,
  }
}

// For request/booking status
export const getCurrentBooking = (bookings) => {
  // an approved booking takes priority
  const approvedBooking = bookings.find((booking) => whatCanIDoNow(booking).bookingCanOccur)
  if (approvedBooking) return approvedBooking
  // then a successful booking
  const successfulBooking = filterBookings({ bookings, groupStatus: 'successful' }).first()
  if (successfulBooking) return successfulBooking
  // otherwise return the one most recently updated
  return bookings.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at)).first()
}

// Extract seats informations from a list of bookings
export const getBookingsSeats = (bookings) =>
  bookings.reduce(
    (acc, booking) => ({
      totalSeats: (acc.totalSeats += booking.seats),
      paidSeats:
        booking.payment && booking.payment.get('payin_status') === 'succeeded'
          ? acc.paidSeats + booking.payment.get('seats')
          : acc.paidSeats,
      freeSeats: (acc.freeSeats += booking.host_free_seats || 0),
    }),
    { totalSeats: 0, paidSeats: 0, freeSeats: 0 },
  )

// Calculates the amount of money earned by the host from a list of bookings
// Returns if `bookings`:
//  - is null --> extectedAmount
//  - contains bookings with paid or successful status -> sum(bookings.payments.host_expected_amount)
//  - only contains bookings with failed status --> undefined
// ⚠️ 0 is a correct value for free events that's why we return undefined
export const computeTotalHostExpectedAmount = (bookings, expectedAmount = undefined) => {
  // If it comes directly from a request, bookings can be null, [] or [Record{}]
  if (!bookings || isEmpty(bookings) || !bookings.first().id) return expectedAmount

  // we only keep booking with paid or successful status and a payment
  const bookingsWithPayment = bookings.filter((booking) => {
    if (!booking.payment) return false
    const { bookingCanBeValidated, bookingCanOccur, bookingHasHappened } = whatCanIDoNow(booking)
    return bookingCanBeValidated || bookingCanOccur || bookingHasHappened
  })

  if (bookingsWithPayment.size === 0) return undefined

  return bookingsWithPayment.reduce((acc, booking) => (acc += booking.payment.get('host_expected_amount')), 0)
}
