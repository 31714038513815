import { screenApi } from 'apis'
import { ActionsCreator } from '../lib'
import { HostApplicationSchema } from 'redux/schemas'
import { getHostApplication, getCurrentUser, getCurrentUserHostApplicationId, getLocation } from 'redux/selectors'
import { getStepProgress } from '../../pages/host-application/hostApplicationHelper'
import getKonstants from '@vizeat/helpers/lib/konstants'

const { HOST_APPLICATION_SCHEMA_VERSION } = getKonstants()

export const hostApplicationsActions = new ActionsCreator({ Api: screenApi, actionTypesPrefix: 'hostApplications' })

export const createHostApplication = (payload) => {
  return hostApplicationsActions.create({
    url: '/hostApplications',
    payload,
    schema: { hostApplication: HostApplicationSchema },
  })
}

export const updateHostApplication = (payload) => (dispatch, getState) => {
  const id = getCurrentUserHostApplicationId(getState())
  const location = getLocation(getState())
  const stepProgress = location && getStepProgress(location.pathname)
  return dispatch(
    hostApplicationsActions.update({
      url: `/hostApplications/${id}`,
      payload: {
        ...payload,
        ...{
          metadata: {
            completion_rate: stepProgress ? stepProgress.completionRate : 0,
            schema_version: `${HOST_APPLICATION_SCHEMA_VERSION}`,
          },
        },
      },
      schema: { hostApplication: HostApplicationSchema },
    }),
  )
}

export const fetchHostApplication = (id, payload) =>
  hostApplicationsActions.fetch({
    url: `/hostApplications/${id}`,
    payload,
    schema: { hostApplication: HostApplicationSchema },
  })

export const submitHostApplication = (payload) => (dispatch, getState) => {
  const id = getCurrentUserHostApplicationId(getState())
  return dispatch(
    hostApplicationsActions.create({
      url: `/hostApplications/${id}`,
      payload,
      schema: { hostApplication: HostApplicationSchema },
    }),
  )
}

export const deleteHostApplication = (id) =>
  hostApplicationsActions.delete({
    url: `/hostApplications/${id}`,
    schema: { hostApplication: HostApplicationSchema },
    deleted: { hostApplications: ['1'] },
  })

const handleCreateApplication = (state, action) => {
  const application = getHostApplication(state)
  const currentUser = getCurrentUser(state)
  const updatedUser = currentUser.set('host_application_id', application.id)
  return state.setIn(['entities', 'currentUsers', String(currentUser.id)], updatedUser)
}

export const hostApplicationCustomHandlers = {
  [hostApplicationsActions.actionTypes.CREATED]: handleCreateApplication,
}
