import platformHelpers from '@vizeat/helpers/es6/platforms'
const { isInternationalPlatform, isChinesePlatform } = platformHelpers()

function getDomainSpecificVariables() {
  const hostname = window.location.hostname

  if (hostname === 'my.eatwith.com')
    return {
      cookieDomainName: '.eatwith.com',
      domainName: 'eatwith.com',
      screenApiUrl: 'https://screen-api.eatwith.com',
    }
  if (hostname === 'my.vizeater.co')
    return {
      cookieDomainName: '.vizeater.co',
      domainName: 'vizeater.co',
      screenApiUrl: 'https://screen-api.vizeater.co',
    }
  if (hostname === 'my.vizeater.info')
    return {
      cookieDomainName: '.vizeater.info',
      domainName: 'vizeater.info',
      screenApiUrl: 'https://screen-api.vizeater.info',
    }

  if (hostname === 'my.eatwith.net.cn')
    return {
      cookieDomainName: '.eatwith.net.cn',
      domainName: 'eatwith.net.cn',
      screenApiUrl: 'https://screen-api.eatwith.net.cn',
    }
  if (hostname === 'uat-my.eatwith.net.cn')
    return {
      cookieDomainName: '.eatwith.net.cn',
      domainName: 'eatwith.net.cn',
      screenApiUrl: 'https://uat-screen-api.eatwith.net.cn',
    }

  if (hostname === 'my.ew.local')
    return {
      cookieDomainName: undefined,
      domainName: 'ew.local',
      screenApiUrl: 'https://screen-api.ew.local',
    }

  return {
    cookieDomainName: undefined, // Must be undefined so each deploy link can store cookies
    domainName: 'vizeater.co',
    screenApiUrl: process.env.SCREEN_API_URL || 'https://screen-api.vizeater.co',
  }
}

const { cookieDomainName, domainName, screenApiUrl } = getDomainSpecificVariables()

const settings = {
  domainName: domainName,
  cookieDomainName,
  apiUrl: screenApiUrl,
  fileUrl: screenApiUrl,
  isInternationalPlatform: isInternationalPlatform(domainName),
  isChinesePlatform: isChinesePlatform(domainName),

  widgetScriptUrl: process.env.WIDGET_SCRIPT_URL,
  screenApiVersion: process.env.SCREEN_API_VERSION,
  cloudFlareWorkersApi: process.env.CLOUDFLARE_WORKERS_API,
  // Sentry
  sentry: {
    dsn: process.env.SENTRY_DSN,
    environment: process.env.SENTRY_ENVIRONMENT,
    release: process.env.CF_PAGES_COMMIT_SHA, // this comes directly from Cloudflare Pages environment variables
    tracesSampleRate: process.env.SENTRY_TRACES_SAMPLE_RATE,
  },
  // Geo
  geo: {
    autocomplete: process.env.PUBLIC_GEO_AUTOCOMPLETE_URL,
    geocode: process.env.PUBLIC_GEO_GEOCODE_URL,
  },
  // Uploadcare
  uploadCareKey: process.env.UPLOADCARE_KEY,
  // Google
  googleApiClientId: process.env.GOOGLE_API_CLIENT_ID,
  // Google Tag Manager
  gtmId: process.env.GOOGLE_TAG_MANAGER_ID,
  // Stripe
  stripePublicKey: process.env.STRIPE_PUBLIC_KEY,
  // Intercom
  intercomApiId: process.env.INTERCOM_API_ID,
  // Facebook
  facebookAppId: process.env.FACEBOOK_APP_ID,
  // Weibo
  weiboAppKey: process.env.WEIBO_APP_KEY,
}

export default settings
