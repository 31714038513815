import { createSelector } from 'reselect'
import { List } from 'immutable'
import {
  getEntities,
  getEntity,
  getFetchError,
  getObjId,
  getCreateError,
  getUpdateError,
  getDeleteError,
  isDeleting,
  isFetching,
  isCreating,
  isUpdating,
} from './_utils'

import {
  getAlcohol,
  getBookings,
  getCurrency,
  getDiet,
  getFile,
  getFood,
  getLanguage,
  getOverride,
  getPlace,
  getUser,
  getUserAvatar,
  getAppLocale,
  getUserEvents,
  scheduleIsBookableAndOpened,
} from 'redux/selectors'

import { emptyOverride } from 'redux/schemas'

import { getFiles } from './files' // need not to refer redux/selectors/index, most likely due to circular dependencies

export const getEvents = getEntities('events')
export const getEvent = getEntity('events')

export const getEventHost = createSelector(
  getEvent,
  (state) => state,
  (event, state) => getUser(state, getObjId(event, 'user')),
)

export const getEventHostLanguages = createSelector(
  getEventHost,
  (state) => state,
  (host, state) => host.languages.map((id) => getLanguage(state, id)),
)

export const getEventCover = createSelector(
  getEvent,
  (state) => state,
  (event, state) => getFile(state, getObjId(event, 'cover')),
)

export const getEventFiles = createSelector(getEvent, getFiles, (event, files) =>
  files
    .filter((file) => file.attachable_type === 'events' && file.attachable_id === event.id)
    .toList()
    .sort((a, b) => {
      if (a.category === 'cover') return -1
      if (b.category === 'cover') return 1
      return a.order - b.order
    }),
)

const getEventFilesWithoutCover = createSelector(getEventCover, getEventFiles, (cover, files) =>
  files.filter((file) => file.category !== 'cover'),
)

export const getEventFoodsFiles = createSelector(getEventFilesWithoutCover, (files) =>
  files.filter((file) => file.category === 'food'),
)

export const getEventVenuesFiles = createSelector(getEventFilesWithoutCover, (files) =>
  files.filter((file) => file.category === 'venue'),
)

export const getEventOtherFiles = createSelector(getEventFilesWithoutCover, (files) =>
  files.filter((file) => file.category === 'default'),
)

export const getEventFood = createSelector(
  getEvent,
  (state) => state,
  (event, state) => getFood(state, getObjId(event, 'food')),
)

export const getEventAlcohols = createSelector(
  getEvent,
  (state) => state,
  (event, state) => event.alcohols.map((id) => getAlcohol(state, id)),
)

export const getEventPlace = createSelector(
  getEvent,
  (state) => state,
  (event, state) => getPlace(state, getObjId(event, 'place')),
)

export const getEventCurrency = createSelector(
  getEvent,
  (state) => state,
  (event, state) => getCurrency(state, getObjId(event, 'currency')),
)

export const getEventEventCurrency = createSelector(
  getEvent,
  (state) => state,
  (event, state) => getCurrency(state, getObjId(event, 'event_currency')),
)

export const getEventHostFiles = createSelector(getEventHost, getFiles, (host, files) =>
  files
    .filter((file) => file.account_id === (host.account_id || host.account))
    .toList()
    .sort((a, b) => a.order - b.order),
)

export const getEventDiets = createSelector(
  getEvent,
  (state) => state,
  (event, state) => event.diets.map((id) => getDiet(state, id)),
)

export const getEventHostAvatar = createSelector(
  getEventHost,
  (state) => state,
  (host, state) => getUserAvatar(state, host.id),
)

export const getEventBookings = createSelector(getEvent, getBookings, (event, bookings) =>
  bookings.filter((booking) => booking.event_id === event.id),
)

export const getEventDescriptionsInOrder = createSelector(
  getEvent,
  (state, id, describes) => ({ state, describes }),
  (event, { state, describes }) => {
    const descriptions = event.descriptions.filter((d) => d.get('describes') === describes)
    const appLocale = getAppLocale(state)
    const availableLanguages = descriptions
      .map((desc) => desc.get('language_id'))
      .toSet()
      .toList()
      .map((id) => getLanguage(state, id))
    const langId = (
      availableLanguages.find((l) => l.locale === appLocale) ||
      availableLanguages.find((l) => l.locale === 'en') ||
      availableLanguages.first() ||
      {}
    ).id
    if (!langId) return new List()
    return descriptions
      .filter((desc) => desc.get('language_id') === langId)
      .sortBy(
        (desc) => desc.get('order'),
        (o1, o2) => o1 - o2,
      )
  },
)

export const getOpenBookableEventAlternatives = createSelector(
  (state, userId, date, eventId) => getUserEvents(state, userId),
  (state, userId, date, eventId) => ({ state, date, eventId }),
  (events, { state, date, eventId }) => {
    return events
      .filter(({ id }) => id !== eventId && scheduleIsBookableAndOpened(state, date, id))
      .map(({ id }) => getEventWithOverrides(state, id, date.format('YYYY-MM-DD')))
  },
)

// Args: id, date
export const getEventOverrides = createSelector(
  getEvent,
  (state, id, date) => ({ state, id, date }),
  (event, { state, id, date }) => (date ? getOverride(state, `${id}-${date}`) : emptyOverride),
)

// Args: id, date
export const getEventWithOverrides = createSelector(getEvent, getEventOverrides, (event, overrides) => {
  if (overrides.date) return event.mergeWith((a, b) => (b === undefined ? a : b), overrides)
  return event
})

// loading
export const fetchingEvents = isFetching('/events')
export const fetchingEvent = (state, id) => isFetching(`/events/${id}`)(state)
export const creatingEvent = (state, id) => isCreating(`/events/${id}`)(state)
export const updatingEvent = (state, id) => isUpdating(`/events/${id}`)(state)
export const deletingEvent = (state, id) => isDeleting(`/events/${id}`)(state)
export const updatingEventByDate = (state, id, date) => isUpdating(`/events/${id}/${date.format('YYYY-MM-DD')}`)(state)
export const updatingScheduleEventByDate = (state, id, date) =>
  isUpdating(`/events/${id}/planning/${date.format('YYYY-MM-DD')}`)(state)

// errors
export const getFetchEventError = (state, id) => getFetchError(`/events/${id}`)(state)
export const getCreateEventError = (state, id) => getCreateError(`/events/${id}`)(state)
export const getUpdateEventError = (state, id) => getUpdateError(`/events/${id}`)(state)
export const getDeleteEventError = (state, id) => getDeleteError(`/events/${id}`)(state)
