import { createSelector } from 'reselect'
import { getEntitiesState } from './_utils'
import { Map } from 'immutable'
import { getCountryFromIPAddress } from './IPGeolocation'
import { getLanguageByLocale, getCurrencyByIso3 } from 'redux/selectors'
import getKonstants from '@vizeat/helpers/es6/konstants'
const { DEFAULT_CURRENCY } = getKonstants()

const getAppSettings = createSelector(getEntitiesState, (entitiesState) => entitiesState.get('appSettings') || Map())

export const getAppCurrencyIso3 = createSelector(
  getAppSettings,
  (settings) => settings.get('currency') || DEFAULT_CURRENCY.iso_3,
)

export const getAppCurrency = createSelector(
  getAppCurrencyIso3,
  (state) => state,
  (iso3, state) => getCurrencyByIso3(state, iso3),
)

export const getAppLocale = createSelector(getAppSettings, (settings) => settings.get('locale') || 'en')

export const getAppLanguage = createSelector(
  getAppLocale,
  (state) => state,
  (locale, state) => getLanguageByLocale(state, locale),
)

export const getAppInitialised = createSelector(getAppSettings, (settings) => settings.get('appInitialised') || false)

export const getInstagramLink = createSelector(getCountryFromIPAddress, (country) => {
  switch (true) {
    case country === 'ES':
      return 'https://www.instagram.com/eatwith_es/'
    case country === 'GB':
      return 'https://www.instagram.com/eatwithlondon/'
    case country === 'IL':
      return 'https://www.instagram.com/eatwith_il/'
    default:
      return 'https://www.instagram.com/eatwith/'
  }
})

export const getFacebookLink = createSelector(getCountryFromIPAddress, (country) => {
  switch (true) {
    case country === 'ES':
      return 'https://www.facebook.com/EatwithES/'
    case country === 'GB':
      return 'https://www.facebook.com/eatwithlondon/'
    case country === 'IL':
      return 'https://www.facebook.com/Eatwithil/'
    default:
      return 'https://www.facebook.com/Eatwith/'
  }
})
