import { schema } from 'normalizr'
import { Record, Map } from 'immutable'

import { EventSchema } from './events'
import { PlaceSchema } from './places'
import { UserSchema } from './users'

export const BookingSchema = new schema.Entity('bookings', {
  event: EventSchema,
  place: PlaceSchema,
  user: UserSchema,
  // request schema is linked in ./_utils.js due to circular dependencies
})

export const BookingRecord = Record({
  id: undefined,

  additional_info: undefined,
  begins_at: undefined,
  conversation: undefined,
  conversation_id: undefined,
  date: undefined,
  ends_at: undefined,
  event: undefined,
  event_id: undefined,
  groupStatus: undefined,
  guest_already_left_review: undefined,
  host_already_left_review: undefined,
  made_by_partner: undefined,
  invite_token: undefined,
  occasion: undefined,
  payment: new Map(),
  place: undefined,
  place_id: undefined,
  publicStatus: undefined,
  reference_code: undefined,
  request_id: undefined,
  seats: undefined,
  host_free_seats: undefined,
  status: undefined,
  user: undefined,
  user_id: undefined,
  should_privatize_date: undefined,

  created_at: undefined,
  updated_at: undefined,
})

export const emptyBooking = new BookingRecord()
