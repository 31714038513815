import settings from 'settings'
import vizeatUrls from '@vizeat/helpers/es6/vizeatUrls'
import { omit, isEqual } from 'lodash'

export const urlFactory = vizeatUrls({ config: { url: { host: settings.domainName }, env: process.env.NODE_ENV } })

export function isCurrentPath(path, location) {
  const { search, pathname, query } = location

  if (typeof path === 'object') {
    const toOmit = ['page', 'c']
    return path.pathname === pathname && isEqual(omit(query, toOmit), omit(path.query, toOmit))
  }
  if (typeof path === 'string') {
    // Avoid currency in querystring
    // If query contains page data, consider only pathname
    // Else consider whole path, search included
    const searchString = search
      .replace(/[?&]c=[A-Z]{3}/, '')
      .replace(/[?&]page=[0-9]+/, '')
      .replace(/^&/, '?')
    return path === `${pathname}${searchString}`
  }
}

export function polyfillURL(locale) {
  return `https://polyfill.io/v3/polyfill.min.js?features=es2015,es2016,es2017,es2018,es5,es6,es7,Object.fromEntries,Object.entries,Array.prototype.flat,IntersectionObserver,Intl,Intl.~locale.${locale},Intl.PluralRules,Intl.PluralRules.~locale.${locale},Intl.RelativeTimeFormat,Intl.RelativeTimeFormat.~locale.${locale},default`
}
